
import Vue from "vue";
// import Header from "@/components/Header.vue"; // import Footer from "@/components/Footer.vue";
// import Footer from "@/components/Footer.vue";

export default Vue.extend({
  name: "DynamicPage",
  components: {
    // Header
    // Footer
  },
  mounted() {
    const $document = document as any;
    $document
      .getElementsByClassName("full-screen")[0]
      .addEventListener("mousemove", function(event: any) {
        $document.querySelectorAll(".eye").forEach((eye: any) => {
          const x = eye.offsetLeft + eye.offsetWidth / 2;
          const y = eye.offsetTop + eye.offsetHeight / 2;
          const rad = Math.atan2(event.pageX - x, event.pageY - y);
          const rot = rad * (180 / Math.PI) * -1 + 180;
          eye.style = `-webkit-transform: rotate(${rot}deg); -moz-transform: rotate(${rot}deg); -ms-transform: rotate(${rot}deg); transform: rotate(${rot}deg)`;
        });
      });
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    }
  }
});
