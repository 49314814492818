
import Vue from "vue";
// import Header from "@/components/Header.vue"; // import Footer from "@/components/Footer.vue";
// import Footer from "@/components/Footer.vue";

export default Vue.extend({
  name: "DynamicPage",
  components: {
    // Header
    // Footer
  },
  mounted() {
    const $document = document as any;
    const pageX = $document.body.clientWidth;
    const pageY = $document.body.clientHeight;
    console.log(pageX, pageY);
    let mouseY = 0;
    let mouseX = 0;
    $document.addEventListener("mousemove", function(event: any) {
      mouseY = event.pageY;
      const yAxis = ((pageY / 2 - mouseY) / pageY) * 300;
      //horizontalAxis
      mouseX = event.pageX / -pageX;
      const xAxis = -mouseX * 100 - 100;
      const eyes = $document.querySelector(".box__ghost-eyes");
      eyes.style = `-webkit-transform: translate(${xAxis}%, - ${yAxis}%);-moz-transform: translate(${xAxis}%, -${yAxis}%);-ms-transform: translate(${xAxis}%, -${yAxis}%);transform: translate(${xAxis}%, -${yAxis}%)`;
    });
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    }
  }
});
